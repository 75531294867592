import React, { useContext } from "react";
import Loadable from "react-loadable";
import EmpresaContext from "../context";
import Loading from "./global-components/Loading";
import Header from "./section-components/banner-select";

const Navbar = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Navbar" */ "./select-components/navbar-select"),
  loading() {
    return <></>;
  }
});
const Ads = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ads" */ "./section-components/ads"),
  loading: Loading
});
// const AdsV2 = Loadable({
//   loader: () =>
//   import(/* webpackChunkName: "adsv2" */ "./section-components/ads-v2"),
//   loading: Loading
// });
const FeaturedProperties = Loadable({
  loader: () =>
    import(/* webpackChunkName: "featured-properties" */ "./section-components/featured-properties"),
  loading: Loading
});
const RecentProperties = Loadable({
  loader: () =>
    import(/* webpackChunkName: "recent-properties" */ "./section-components/recent-properties"),
  loading: Loading
});
const Client2 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "client-2" */ "./section-components/client-2"),
  loading: Loading
});
const Footer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "footer" */ "./global-components/footer"),
  loading: Loading
});
const SocialBar = Loadable({
  loader: () =>
    import(/* webpackChunkName: "social-bar" */ "./global-components/social-bar"),
  loading: Loading
});

const Home_V2 = props => {
  let context = useContext(EmpresaContext);
  return (
    <div>
      <Navbar />
      <Header />
      <FeaturedProperties />
      {/* <AdsV2 /> */}
      <Ads />
      <RecentProperties />
      {/* <Client /> */}
      <Client2 />
      {/* <FeaturedProject /> */}
      {
        //<WhyChooseUs />
        //<OurPartner />
      }
      {/* <FooterV2 /> */}
      <Footer />
      <SocialBar />
    </div>
  );
};

export default Home_V2;
